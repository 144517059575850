import {Messenger as BaseMessenger} from 'js-messenger' 
import { KeyManager } from "./KeyManager";

const NO_VERIFY = new Set(["pubkey_resp", "uninstall_detection", "goblin_pubkey_resp", "register_response", "establish_messaging_trust"])
const NO_DECRYPT = new Set(["uninstall_detection", "stpc_dismiss", "register_response"])
export class WebMessenger extends BaseMessenger {
    constructor(keyManager: KeyManager) {
        super(false, keyManager.mctx, keyManager)

        // used by base class
        this.noVerify = NO_VERIFY
        this.noDecrypt = NO_DECRYPT
    }
}
