import React, { useState } from 'react';
//Css
import '../App.css';
const useContainerHooks = () => {
    const [testResults, setTestResults] = useState<string | undefined>(undefined)
    const testRunner = async () => {
        setTestResults("TODO: test 1\n")
        await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
        setTestResults("TODO: test 1\nTODO: test 2\n")
        setTestResults("2/2 PASSED")
    }
    return {
        functions: {
            testRunner,
        },
        state: {
            testResults,
        }
    }
}
const TestContainer = () => {
    const hooks = useContainerHooks();
    const { functions, state } = hooks;
    const {
        testRunner
    } = functions;
    const { testResults } = state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => { testRunner() }, [])
    const display = () => {
        return <pre className="testResults">{testResults}</pre>
    }
    return (
        <div className="container">
            <React.Fragment>
                {display()}
            </React.Fragment>
        </div>
    );
}
export default TestContainer;
