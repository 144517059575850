import {MessengerContext as BaseMessengerContext} from 'js-messenger'
import {Utilities, Constants} from 'js-messenger'

export class MessengerContext extends BaseMessengerContext {
    __keyTag: string
    __devices: Map<string, Object>
    __devOrProd: string

    constructor(devOrProd: string) {
        super()
        this.__devOrProd = devOrProd
        this.__keyTag = "atakama"
        this.__devices = new Map<string, Object>()
    }

    getKeyTag() : string {
        return this.__keyTag
    }

    setKeyTag(keyTag: string) : void {
        // for tests
        this.__keyTag = keyTag
    }

    getDeviceFromID(idHex0x: string) {
        return this.__devices.get(idHex0x)
    }

    addDevice(id: Buffer, device: Object) {
        this.__devices.set(Utilities.buffer2hex0x(id), device)
    }

    getRelayServer() {
        return Constants.SERVER_LIST[this.__devOrProd]
    }

}
