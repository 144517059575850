/* istanbul ignore file */
export class Storage {
    constructor(databaseName: string, storeName: string) {
        if (this.constructor === Storage) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    clear() {
        throw new Error("Method 'clear()' must be implemented.");
    }

    delete(key: any) {
        throw new Error("Method 'delete()' must be implemented.");
    }

    get(key: any) {
        throw new Error("Method 'get()' must be implemented.");
    }

    getAll() {
        throw new Error("Method 'getAll()' must be implemented")
    }

    getAllKeys() {
        throw new Error("Method 'getAllKeys()' must be implemented")
    }

    has(key: any) {
        throw new Error("Method 'has()' must be implemented.");
    }

    set(key: any, value: any) {
        throw new Error("Method 'set()' must be implemented.");
    }
}
