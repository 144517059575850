import * as Errors from './Error'
import { IDBStorage } from './IDBStorage'
import { KeyManager } from "./KeyManager"
import { WebMessenger } from "./WebMessenger"
import { Utilities, Constants } from 'js-messenger'
import {strict as assert} from 'assert'
import {EXID_STORE_NAMES, DB_VERSION, META_STORE_NAMES, EXID_METADATA_DB_NAME,
    EXPIRY_WINDOW_DAYS} from '../Constants'
// transitive dependency on purpose here
import { ECKey, CURVE_TYPES, VidaKey } from 'CryptVFS-Message'
import { Logger } from 'js-logger'

// todo: verify desiredPow type, number in test
export function makeDownloadHash(pubKey: string, exid: Exid, desiredPow: number, relayUrl?: string, contactId?: string, mpkId?: string) {
    let out = pubKey + "~" + exid + (desiredPow ? ('~' + desiredPow) : "")
    if (relayUrl || contactId || mpkId) {
        out += "~" + Utilities.buffer2base64(Buffer.from(relayUrl || ""))
    }
    if (contactId || mpkId) {
        out += "~" + (contactId || "")
    }
    if (mpkId) {
        out += "~" + mpkId
    }
    return out
}

function urlSafe2b64(str: string): string {
    return str.replace(/-/g, '+').replace(/_/g, '/')
}

export async function expireOldExids(exidMeta: IDBStorage) {
    const oneDayMilli = 1000 * 60 * 60 * 24
    for (const item of await exidMeta.getAll()) {
        let expires = new Date(item.value.created.valueOf() + EXPIRY_WINDOW_DAYS * oneDayMilli)
        if (new Date() >= expires) {
            indexedDB.deleteDatabase(item.key)
            await exidMeta.delete(item.key)
        }
    }
}

export async function getStorage(dbName: string) {
    let exidMetadata = await IDBStorage.new(EXID_METADATA_DB_NAME,
        META_STORE_NAMES.EXID_META, Object.values(META_STORE_NAMES), DB_VERSION)
    await expireOldExids(exidMetadata)
    let fileInfo = await IDBStorage.new(dbName, EXID_STORE_NAMES.FILE_INFO,
        Object.values(EXID_STORE_NAMES), DB_VERSION)
    let encChunks = await IDBStorage.new(dbName, EXID_STORE_NAMES.ENCRYPTED_CHUNKS,
        Object.values(EXID_STORE_NAMES), DB_VERSION)
    let decFiles = await IDBStorage.new(dbName, EXID_STORE_NAMES.DECRYPTED_FILES,
        Object.values(EXID_STORE_NAMES), DB_VERSION)

    return {
        exidMetadata: exidMetadata,
        fileInfo: fileInfo,
        encryptedChunks: encChunks,
        decryptedFiles: decFiles,
    }

}

export function getEnvType(): string {
    const hostname = window.location.hostname
    if (hostname.indexOf(".cloudfront.net") !== -1 ||
        hostname.indexOf("localhost") !== -1 ||
        hostname.indexOf("127.0.0.1") !== -1 ||
        hostname.indexOf("-dev.") !== -1 ||
        hostname.indexOf(".amazonaws.com") !== -1 ||
        hostname.indexOf("vidaprivacy.io") !== -1) {
        return Constants.ENV_TYPES.Development
    }
    return Constants.ENV_TYPES.Production
}

export function parseDownloadURL() {
    try {
        let frag = window.location.hash.substr(1);
        let info = frag.split("~")
        let pubKey = urlSafe2b64(info[0])
        let exid = urlSafe2b64(info[1])

        // Old senders will not supply this
        let relayUrl = info[2]
        if (relayUrl) {
            relayUrl = Utilities.base642buffer(relayUrl, "base64").toString("utf-8")
        }

        let contactId
        let mpkId
        if (info[3]) {
            contactId = urlSafe2b64(info[3])
        }
        if (info[4]) {
            mpkId = urlSafe2b64(info[4])
        }

        assert(exid)
        assert(pubKey)
        Buffer.from(exid, "base64")
        Buffer.from(pubKey, "base64")
        return { exid, pubKey, relayUrl, contactId, mpkId }
    } catch (e) {
        Logger.e("Url parse error", e)
        throw new Errors.InvalidURLError("Invalid Download URL")
    }
}

export class DownloadManager {
    // the property name ! syntax allows for declaration while skipping initialization
    // This section needed for any this. property
    exidB64!: Exid
    keyManager!: KeyManager
    messenger!: WebMessenger
    receivedExportList!: boolean
    reconnect: boolean = false
    senderID!: Buffer
    senderPubKeyB64!: string
    words: string = "";
    storage!: {
        fileInfo: IDBStorage,
        encryptedChunks: IDBStorage,
        decryptedFiles: IDBStorage,
        exidMetadata: IDBStorage
    }
    callback!: Function
    oidsInDecryption!: Set<Oid>
    contactId64?: string;
    private mpkId64?: string;
    private saltB64!: Salt;

    static async new(passphrase?: string, fileInfoCallback?: (fileInfo: FileInfo[]) => void) {
        // because async constructors don't exist
        return await ((new DownloadManager()).create(passphrase, undefined, fileInfoCallback))
    }

    parseURL() {
        return parseDownloadURL()
    }

    async create(passphrase?: string, keyManager?: KeyManager, callback?: Function) {
        const start = Date.now()
        this.oidsInDecryption = new Set()

        this.receivedExportList = false  // Currently only used for tests
        if (callback) this.callback = callback
        let {exid, pubKey, relayUrl, mpkId, contactId} = this.parseURL()

        this.mpkId64 = mpkId
        this.contactId64 = contactId
        this.exidB64 = exid

        // contactId used if defined and non-empty, otherwise exid is used
        this.saltB64 = contactId || exid

        this.senderPubKeyB64 = pubKey
        Logger.l("url", this.exidB64, this.senderPubKeyB64, "salt", this.saltB64)

        this.storage = await getStorage(this.exidB64)

        //Check if key information already exists in storage, indicating this is a re-connect
        const exidMetadata: ExidMetadata = await this.storage.exidMetadata.get(this.exidB64)
        this.reconnect = exidMetadata !== undefined
        let privKeyBuf
        let pubEx64
        if (this.reconnect) {
            Logger.l("reconnect")
            privKeyBuf = Buffer.from(exidMetadata.privateKeyBuf)
            pubEx64 = exidMetadata.publicKeyEx64
            this.words = exidMetadata.words
            this.keyManager = new KeyManager(this.saltB64, getEnvType(), undefined, (global as any).OVERRIDE_POW_BITS, privKeyBuf)
        } else {
            // We need to normalize the passphrase. Choosing NFC over NFD was arbitrary. The NFK variants would reduce
            // the passphrase space even more, without any UX improvements, which we don't want.
            this.keyManager = keyManager || new KeyManager(this.saltB64, getEnvType(), passphrase && passphrase.normalize("NFC"), (global as any).OVERRIDE_POW_BITS)
        }

        await this.keyManager.ensureKeychainStorage()
        this.words = this.words !== "" ? this.words : await this.keyManager.getVerifyWords()

        // get sender id
        try {
            this.senderID = await this.keyManager.addDevice(this.senderPubKeyB64)
        } catch (e) {
            Logger.e("Add device error", e)
            throw new Errors.InvalidURLError("The sender's information appears invalid")
        }

        let matchId64 = this.keyManager.getId().toString("base64")

        if (this.mpkId64 && (matchId64 !== this.mpkId64)) {
            throw new Errors.InvalidPasswordError("Your passphrase appears invalid")
        }

        // spin up messenger
        this.messenger = new WebMessenger(this.keyManager)

        Logger.l("attach handlers")

        this.messenger.attachHandler('file_chunk', this.handleFileChunk.bind(this))
        this.messenger.attachHandler('export_list', this.handleExportList.bind(this))

        // TODO: address relay server negotiation of POW, probably in JS-messenger/Websocket connection
        // with callback to utils/storage
        pubEx64 = pubEx64 ?? (await this.keyManager.getPubEx()).toString("base64")

        // connect to server
        if (!relayUrl) {
            relayUrl = this.keyManager.mctx.getRelayServer()
        }
        await this.messenger.ensureConnections(relayUrl)

        // send the first 'ready' message only if this isn't a reconnect
        if (!this.reconnect) {
            await this.messenger.sendMessage('web_client_ready', {
                publicKey: pubEx64,
                exid: this.exidB64
            }, this.senderID)

            // persist info to support re-connecting at a later time
            const keyTag = await this.keyManager.mctx.getKeyTag()
            let privKeyHex = (global as any).Keychain.getGenericPassword(keyTag).password
            let privKeyBuf = Buffer.from(privKeyHex, "hex")
            this.storage.exidMetadata.set(this.exidB64,
                { privateKeyBuf: privKeyBuf,
                  publicKeyEx64: pubEx64,
                  words: this.words,
                  created: new Date(),
                  exid: this.exidB64 } as ExidMetadata)
        }
        Logger.l("Time to initialize DL manager:", Date.now() - start)

        return this
    }

    async closeAllDatabases() {
        if (this.storage) {
            for (const db of Object.values(this.storage)) {
                await db.conn.close()
            }
        }
    }

    toStorageName(oid: Oid, partNum: number): string {
        return oid + "/" + partNum
    }

    async handleExportList(messenger: WebMessenger, from: any, data: any) {
        Logger.l("handleExportList begin")
        if (!from.equals(this.senderID)) {
            Logger.w("Invalid sender for file chunk", from)
            return
        }
        if (!this.contactId64 && !data.exid.equals(Utilities.base642buffer(this.exidB64))) {
            Logger.w("Bad exid", data.exid)
            return
        }

        for (const item of data.fileInfo) {
            // From array to object with did as key
            if (await this.storage.fileInfo.has(item.objectID)) {
                Logger.l("Skipping oid we already have", item.objectID)
                continue
            }

            let pkg: ProvidePackage = {}
            const partList: [{ did: string, encPart: string }] = JSON.parse(item.providePackage)
            for (const entry of partList) {
                pkg[entry.did] = entry.encPart
            }
            item.providePackage = pkg
            item.sourceKey = data.sourceKey
            
            await this.storage.fileInfo.set(item.objectID, item as FileInfo)
        }
        await this.callCallback()
        this.receivedExportList = true
        const allOids: Oid[] = await this.storage.fileInfo.getAllKeys()
        for (const oid of allOids) {
            await this.tryToComplete(oid)
        }
        Logger.l("handleExportList complete")
    }

    async handleFileChunk(messenger: any, from: any, data: any) {
        // We may process this before the export list - still save it
        if (!from.equals(this.senderID)) {
            Logger.w("Invalid sender for file chunk", from)
            return
        }
        const oid = data.objectID
        const storageName: string = this.toStorageName(oid, data.partNum)
        if (await this.storage.encryptedChunks.has(storageName)) {
            Logger.l("Skipping file chunk we already have", oid)
            return
        }
        await this.storage.encryptedChunks.set(storageName, data.fileChunk)
        await this.tryToComplete(oid)
    }

    async tryToComplete(oid: Oid) {
        try {
            if (await this.isFileComplete(oid)) {
                await this.onFileComplete(oid)
            }
        } catch (err) {
            Logger.e("Exception during tryToComplete", oid, err)
        }
    }

    /*
     * Given an AES key, decrypt oid using AES-256 in GCM. Stores decrypted file in file store.
     */
    async decryptFile(oid: Oid): Promise<void> {
        let info: FileInfo | undefined = await this.storage.fileInfo.get(oid)
        if (info === undefined) {
            throw Error("Tried to decrypt file we don't know about" + oid)
        }

        info.sourceKey = Buffer.from(info.sourceKey)
        info.enckey = Buffer.from(info.enckey)

        let key: Buffer = await this.getAESKey(info.providePackage, info.enckey, info.sourceKey)
        const chunks: Buffer[] = []
        for (let i = 0; i < info.totParts; ++i) {
            chunks.push(Buffer.from(await this.storage.encryptedChunks.get(this.toStorageName(oid, i))))
        }
        const fileB64: string = Buffer.concat(chunks).toString("base64")
        let decryptedB64: string = await ECKey.decryptFilecryptrFile(key, fileB64)
        await this.storage.decryptedFiles.set(oid, Buffer.from(decryptedB64, "base64"))

        info.decrypted = true;
        await this.storage.fileInfo.set(oid, info)

        for (let i = 0; i < info.totParts; ++i) {
            await this.storage.encryptedChunks.delete(this.toStorageName(oid, i))
        }
    }

    /* Gets the AES key for oid. This uses kata to do reconstruction and all the fun ECIES stuff. */
    async getAESKey(pkg: ProvidePackage, enckey: Buffer, sourceKey: Buffer): Promise<Buffer> {
        const skey64 = sourceKey.toString("base64")
        Logger.l("getAESKey", skey64)
        const vk = await (new VidaKey()).fromPublic(skey64)
        const sourceKeyRaw = vk.pubBuf
        const indices: string[] = []
        const parts: string[] = []
        for (const did of Object.keys(pkg)) {
            const encPart: string = pkg[did]
            let part: Buffer = await this.keyManager.privateKey.decrypt(encPart)
            indices.push(did)
            parts.push(part.toString("base64"))
        }
        const sharedSecret: Buffer = await ECKey.vsssPubCombine(CURVE_TYPES.CREDS, indices, parts)
        let pk: Buffer = sourceKeyRaw
        let enckeyB64: string = Buffer.from(enckey).toString("base64")
        return await ECKey.mpkDecrypt(sharedSecret, pk, enckeyB64, Buffer.from([]))
    }

    async onFileComplete(oid: Oid) {
        // Only needs to be done once per file
        if (this.oidsInDecryption.has(oid)) {
            Logger.l("Already in decryption", oid)
            return
        }
        this.oidsInDecryption.add(oid)
        try {
            await this.decryptFile(oid)
            await this.callCallback()
        } finally {
            this.oidsInDecryption.delete(oid)
        }
    }

    async callCallback() {
        const allFileInfo: [{key: string, value: FileInfo}] = await this.storage.fileInfo.getAll()
        this.callback(allFileInfo.map((item) => item.value))
        if (allFileInfo.every(info => info.value.decrypted)) {
            this.messenger.sendMessage('export_files_decrypted',
                                       {exid: Buffer.from(this.exidB64, 'base64')},
                                       this.senderID)
        }
    }

    async isFileComplete(oid: Oid) {
        const info: FileInfo = await this.storage.fileInfo.get(oid)
        if (info !== undefined) {
            let completeFile = true
            for (let i = 0; i < info.totParts; ++i) {
                if (!await this.storage.encryptedChunks.has(this.toStorageName(oid, i))) {
                    completeFile = false
                    break
                }
            }
            return completeFile
        }
        return false
    }

    async sendExportDownloadComplete(oids: Oid[]) {
        await this.messenger.sendMessage('export_download_complete',
                                         {exid: Buffer.from(this.exidB64, 'base64'),
                                          objectIDs: oids},
                                         this.senderID) 
    }
}

