import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { validPassword } from '../modules/KeyManager'
import ReactTooltip from 'react-tooltip';
import Tooltip from '../assets/Tooltip.svg'
import Eye from '../assets/Eye.svg'
import EyeSlash from '../assets/EyeSlash.svg'
import * as Errors from '../modules/Error'


export const useSecureOptionsHooks = (onOptionSelected: (passphrase?: string) => void) => {
    const [passphrase, setPassphrase] = useState<string | undefined>(undefined)
    const [passphraseSecond, setPassphraseSecond] = useState<string | undefined>(undefined)
    const [passphraseSelected, setPassphraseSelected] = useState(false)
    const [error, setError] = useState("")
    const [isRevealed, setIsRevealed] = useState(false)

    const handlePassphraseOption = (existingPassword: boolean) => {
        try {
            setIsRevealed(false)
            if (existingPassword) {
                validPassword(passphrase)
            } else {
                validateInput(passphrase, passphraseSecond, true)
            }
            onOptionSelected(passphrase)
            // eslint-disable-next-line no-empty
        } catch (e) {
            setError(String(e))
        }
    }

    const validateInput = (first: string | undefined, second: string | undefined, submitting: boolean = false) => {
        try {
            validPassword(first)
            confirmMatch(first, second, submitting)
            setError("")
        } catch (e) {
            if (submitting) { throw e }
            setError(String(e))
        }
    }

    const confirmMatch = (first: string | undefined, second: string | undefined, submitting: boolean = false) => {
        if ((submitting && !second?.length) || (second?.length && first !== second)) {
            throw new Errors.PassphrasesDontMatch("Passphrases do not match")
        }
    }

    const toggleRevealPassword = () => {
        setIsRevealed(!isRevealed)
    }

    return {
        functions: {
            handlePassphraseOption,
            setPassphraseSelected,
            setPassphrase,
            setPassphraseSecond,
            toggleRevealPassword,
            validateInput,
            setError
        },
        state: {
            passphrase,
            passphraseSecond,
            passphraseSelected,
            error,
            isRevealed
        }
    }
}

interface Props {
    onOptionSelected: (passphrase?: string) => void
    forcePassword: boolean,
    firstTimeForRepeatContact: boolean
}

const SecureOptions = (props: Props) => {
    const { onOptionSelected, forcePassword, firstTimeForRepeatContact } = props;

    const hooks = useSecureOptionsHooks(onOptionSelected);
    const { functions, state } = hooks;
    const { handlePassphraseOption, setPassphraseSelected, toggleRevealPassword, setPassphrase,
        setPassphraseSecond, validateInput, setError } = functions;
    const { passphrase, passphraseSecond, passphraseSelected, error, isRevealed } = state;


    const passphraseDisplay = (existingPassword: boolean) => {
        return <div className="secure-option-passphrase">
            <div>
                <p className="passphrase-text-primary">{existingPassword ? "Please enter your passphrase." : "Please create a passphrase with at least 15 characters."}</p>
                {existingPassword ? null : <p className="passphrase-text-secondary">Use a memorable passphrase, as you will need it in the future to receive additional encrypted files</p>}
                <div className="passphrase-container">
                    <div className={existingPassword ? "passphrase-input-container bottom" : "passphrase-input-container top"}>
                        <input
                            id="passphrase"
                            name="passphrase"
                            type={isRevealed ? "text" : "password"}
                            placeholder='Passphrase'
                            aria-label='Passphrase'
                            className='passphrase-input'
                            onChange={(e) => { setError(""); setPassphrase(e.target.value) }}
                            onBlur={existingPassword ? () => { } : (e) => validateInput(e.target.value, passphraseSecond) } />
                        {passphrase?.length ? <span onClick={toggleRevealPassword} className="eye-span"><img className="eye" src={isRevealed ? EyeSlash : Eye} alt="eyeball" /></span> : null}
                    </div>
                    {existingPassword ? null :
                        <div className="passphrase-input-container bottom">
                            <input
                                id="passphrase-second"
                                name="passphrase-second"
                                type={isRevealed ? "text" : "password"}
                                placeholder='Confirm'
                                aria-label='Confirm'
                                className='passphrase-input'
                                onChange={(e) => { setError(""); setPassphraseSecond(e.target.value) }}
                                onBlur={(e) => validateInput(passphrase, e.target.value)} />
                            {passphrase?.length ? <span onClick={toggleRevealPassword} className="eye-span"><img className="eye" src={isRevealed ? EyeSlash : Eye} alt="eyeball" /></span> : null}
                        </div>
                    }
                    <div className="cancel-accept-button-container">
                        {forcePassword ? null :
                            <button className="cancel-button" onClick={() => setPassphraseSelected(false)}>
                                <span>
                                    Cancel
                                </span>
                            </button>
                        }
                        <button id="passphrase_next_button" className="standard-button" onClick={() => handlePassphraseOption(existingPassword)}>
                            <span>
                                Next
                            </span>
                        </button>
                    </div>
                </div>
                <div className="passphrase-error">{error}</div>
            </div>
        </div>
    }

    return <div className="secure-options">
        {(passphraseSelected || forcePassword) ? passphraseDisplay(!firstTimeForRepeatContact) :
            <div className="column-option-container">
                <div className="secure-option">
                    <h3>Option 1: <span className="brand-4-color">Generate an Atakama Key</span></h3>
                    <div className="option-text-container">
                        <div className="pro-con-container">
                            <p className="bullet-heavy">&bull;</p>
                            <p data-tip="Generally appropriate for government and military applications.">Maximizes security, as you are not subject to someone guessing your passphrase.&nbsp;<img className="tooltip" src={Tooltip} alt="" /></p>
                        </div>
                        <div className="pro-con-container"><p className="bullet-heavy">&bull;</p><p>No passphrase to remember.</p></div>
                        <div className="pro-con-container">
                            <p className="bullet-heavy">&bull;</p>
                            <p data-tip="Regardless of which option you choose, you must wait for the sender to release the file." >You’ll need to complete the file transfer on this computer and in this web browser.&nbsp;<img className="tooltip" src={Tooltip} alt="" />
                            </p>
                        </div>
                        <ReactTooltip />
                    </div>
                    <button id="generate_button" className="option-button" onClick={() => onOptionSelected()}>
                        <span>
                            Generate a Key
                        </span>
                    </button>
                </div>
                <div className="verticalLine" />
                <div className="secure-option">
                    <h3>Option 2: <span className="brand-4-color">Create a Passphrase</span></h3>
                    <div className="option-text-container">
                        <div className="pro-con-container">
                            <p className="bullet-heavy">&bull;</p>
                            <p data-tip="Generally appropriate for commercial applications.">Security depends on passphrase. The longer and more complex that passphrase, the more secure.&nbsp;<img className="tooltip" src={Tooltip} alt="" />
                            </p>
                        </div>
                        <div className="pro-con-container"><p className="bullet-heavy">&bull;</p><p>If you forget your passphrase, the sender will need to send you a new download link.</p></div>
                        <div className="pro-con-container">
                            <p className="bullet-heavy">&bull;</p>
                            <p data-tip="Regardless of which option you choose, you must wait for the sender to release the file.">You can choose a passphrase now and use a different computer to download the file later.&nbsp;<img className="tooltip" src={Tooltip} alt="" />
                            </p>
                        </div>
                    </div>
                    <button id="passphrase_button" className="option-button" onClick={() => setPassphraseSelected(true)}>
                        <span>
                            Create a Passphrase
                        </span>
                    </button>
                </div>
            </div>
        }
    </div>
}

SecureOptions.propTypes = {
    onOptionSelected: PropTypes.func.isRequired
};

export default SecureOptions
