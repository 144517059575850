import React, { useState } from 'react';
import PropTypes from 'prop-types';

interface Props {
    handleAcceptExport: () => void
}

export const useLandingPageHooks = () => {
    const [title, setTitle] = useState('')

    const setCharAt = function (str: string, index: number, chr: string) {
        return str.substr(0, index) + chr + str.substr(index + 1);
    }
    const getRandomValue = function (array: number[]) {
        var randomIndex = Math.floor(Math.random() * array.length);
        var randomValue = array[randomIndex];
        array.splice(randomIndex, 1);
        return randomValue;
    };
    const changeLetter = function (input: string, garbage: string, iter = 0, transformIndicies?: number[]) {
        if (input !== garbage) {
            if (!transformIndicies) {
                transformIndicies = new Array(input.length)
                for (let i = 0; i < input.length; i++) {
                    transformIndicies[i] = i
                }
            }
            let idx = getRandomValue(transformIndicies) // random order
            // let idx = transformIndicies.pop() // right to left
            // let idx = transformIndicies.shift() // left to right
            garbage = setCharAt(garbage, idx, input.charAt(idx));
            setTitle(garbage)
            iter++
            setTimeout(() => {
                // Delay before moving to next letter
                requestAnimationFrame(() => {
                    changeLetter(input, garbage, iter, transformIndicies)
                })
            }, 20)
        }
    };

    const unscrambleTitle = () => {
        let garbage = "∆≥:ëë≈∆%±-%∆®îƒ©∫√çç"
        setTitle(garbage)
        setTimeout(() => {
            // Delay before unscramble.
            requestAnimationFrame(() => {
                changeLetter("Secure File Transfer", garbage)
            })
        }, 1000)
    }

    return {
        functions: {
            unscrambleTitle
        },
        state: {
            title
        }
    }
}

const LandingPage = (props: Props) => {
    const { handleAcceptExport } = props
    const hooks = useLandingPageHooks()
    const { functions, state } = hooks
    const { title } = state
    const { unscrambleTitle } = functions

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(unscrambleTitle, [])

    return <div className="standard-page-layout">
        <h1 className="title">{title}</h1>
        <div className="general-information">Encrypted files have been shared with you.
        </div>
        <div className="general-information">Click begin to receive the files.</div>

        <button id="begin_button" className="standard-button" onClick={handleAcceptExport}>
            <span>
                Begin
            </span>
        </button>
    </div>
}

LandingPage.propTypes = {
    handleAcceptExport: PropTypes.func.isRequired
};

export default LandingPage