/* istanbul ignore file */
export const EXPORT_STATUS = Object.freeze({
    initial: "initial",
    error: "error",
    passphrasePrompt: 'preAuth',
    keyDerivationInProgress: 'keyDerivationInProgress',
    waitingOnRelease: 'waitingOnRelease',
    receivingFiles: 'receivingFiles'
})

export enum WATERMARK_POSITIONS {
    bottom = "bottom",
    center = "center"
}
