import React from 'react';
import './App.css';
import Container from './components/Container'
import TestContainer from './components/TestContainer'
import logo from './assets/logo-dark-bg.svg';

function App() {
  if (window.location.hash === "#run-self-test") {
      return (
        <div className="app">
          <TestContainer></TestContainer>
        </div>
      );
  }
  return (
    <div className="app">
      <header className="app-header">
        <a href="https://www.atakama.com/" target="_blank" rel="noopener noreferrer" className="logo-link"><img className="logo" src={logo} alt="Atakama" /></a>
      </header>
      <Container></Container>
      <footer className="bottom-hero">
        <h5 className="bottom-hero-header">Interested in Atakama?</h5>
        <a href="https://www.atakama.com/" target="_blank" rel="noopener noreferrer" className="button-link"><span>Learn More</span></a>
      </footer>
    </div>
  );
}

export default App;
