import {VidaKey, WordsMethod } from 'CryptVFS-Message'
import { Logger } from 'js-logger'
import {KeyManager as BaseManager} from 'js-messenger'
import {MessengerContext} from './MessengerContext'
import * as Errors from './Error'

export function validPassword(passphrase?: string) : void {
    if (!passphrase) {
        throw new Errors.PasswordRequiredError("Passphrase required")
    }
    if (passphrase.length < 15) {
        throw new Errors.WeakPasswordError("Passphrase must be 15 characters")
    }
}

export const EXPORT_KEY_POW_BITS = 19

export class KeyManager extends BaseManager {
    salt: Salt
    password?: string
    privKeyBuf?: Buffer
    _verifyWords: string
    mctx: MessengerContext
    powBits: number
    iterations: number

    constructor(salt: Salt, devOrProd: string, password?: string, powBits: number = EXPORT_KEY_POW_BITS, privKeyBuf?: Buffer) {
        let mctx = new MessengerContext(devOrProd)
        super(false, mctx)

        if (password)
            validPassword(password)

        this._verifyWords = ""
        this.mctx = mctx
        this.salt = salt
        this.password = password
        this.powBits = powBits  // for my key, NOT verification of others'
        this.iterations = (global as any).OVERRIDE_STRETCH || 5000000
        this.privKeyBuf = privKeyBuf
        Logger.l("iterations", this.iterations, "powBits", this.powBits)
    }

    async getVerifyWords() : Promise<string> {
        if (!this._verifyWords) {
            this._verifyWords = await this.privateKey.getVerifyWords(
                {sep:'-', cnt:4, powBits: this.powBits, method:WordsMethod.MPK_POW})
        }
        return this._verifyWords
    }

    async getPubEx() : Promise<Buffer> {
        // public key in ex_ format
        return await this.privateKey.getPubEx(this.powBits)
    }

    async _initFromKeychain() {
        // this is the primary initialization function
        // it is called from ensureKeychainStorage()
        // that is basically the async constructor

        const keyTag = await this.mctx.getKeyTag()
        let vk = new VidaKey()
        if (this.privKeyBuf) {
            // create from key in storage
            await vk.fromPrivateBuf(this.privKeyBuf, keyTag)
        } else if (this.password) {
            // Derive key from password, and store it in the given keytag
            const derived = await this.stretchKey(this.salt, this.password)
            await vk.fromDerived(derived, keyTag)
        } else {
            // Generate random key (or fetch existing) and store it in the keytag
            await vk.fromKeyTag(keyTag)
        }
        Logger.l("KeyManager: Super INIT")
        await super._initFromKeychain()

    }


    /* istanbul ignore next */
    async stretchKey(salt: Salt, passphrase: any) {
        // use passphrase if provided
        const base = await crypto.subtle.importKey(
            "raw",
            Buffer.from(passphrase, "utf8"),
            "PBKDF2",
            false,
            ["deriveBits"]
        );
        const key = await crypto.subtle.deriveBits(
            {
                name: "PBKDF2",
                salt: Buffer.from(salt, "utf8"),
                iterations: this.iterations,
                hash: "SHA-256"
            },
            base,
            256,
        );
        Logger.l("derived a key")
        return key
    }

    async addDevice(publicKey: string) : Promise<Buffer> {
        let vk = await (new VidaKey()).fromPublic(publicKey)
        let id = vk.hashid()
        this.mctx.addDevice(id, {publicKey})
        return id
    }
}
