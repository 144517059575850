import React from 'react';

//#region PDF Imports
//Core
import { Worker, Viewer, RenderPageProps, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
//Toolbar
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { ToolbarSlot, ToolbarProps } from '@react-pdf-viewer/toolbar';
//#endregion

import {WATERMARK_POSITIONS} from '../Enums'

interface Props {
    togglePDF: (showPDF: boolean) => void
    pdf: PDF
}

const PDFViewer = (props: Props) => {
    const { togglePDF, pdf } = props
    const {
        watermarkText,
        watermarkOpacity,
        watermarkPosition,
        fileContents
    } = pdf

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    // We do not want users to have these options on the pdfviewer 
    // This is the react-pdf-viewer way of removing the buttons
    const transform = (slot: ToolbarSlot) => {
        return Object.assign({}, slot, {
            Download: () => (
                <>
                </>
            ),
            Print: () => (
                <>
                </>
            ),
            Open: () => (
                <>
                </>
            ),
            EnterFullScreen: () => (
                <>
                </>
            ),
        });
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
            <>
                <div className="button-container-full">
                    <button id="back_button" className="standard-button" onClick={() => togglePDF(false)}>
                        <span>
                            Back to Files
                        </span>
                    </button>
                </div>

                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            </>
        ),
        sidebarTabs: (defaultTabs) => [defaultTabs[1]],
    });

    const watermarkContent = (props: RenderPageProps) => {
        let align, rotate, fontScale
        if (watermarkPosition === WATERMARK_POSITIONS.bottom) {
            align = 'flex-end'
            rotate = '0'
            fontScale = 3
        } else {
            align = 'center'
            rotate = '-45'
            fontScale = 4
        }

        return <div
            style={{
                alignItems: align,
                display: 'flex',
                height: '100%',
                justifyContent: align,
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
            }}
        >
            <div
                style={{
                    color: 'rgba(0, 0, 0, 1)',
                    fontSize: `${fontScale * props.scale}rem`,
                    fontWeight: 'bold',
                    opacity: watermarkOpacity ?? .5,
                    transform: `rotate(${rotate}deg)`,
                    userSelect: 'none',
                }}
            >
                {watermarkText}
            </div>
        </div>
    }

    const renderPage = (props: RenderPageProps) => {
        return (
            <>
                {props.canvasLayer.children}
                <div style={{ userSelect: 'none' }}>
                    {props.textLayer.children}
                </div>
                {watermarkText ? watermarkContent(props) : null}
                {props.annotationLayer.children}
            </>
        );
    };

    return <div className="pdf-container">
        <Worker workerUrl={`${process.env.PUBLIC_URL}/static/js/pdf.worker.min.js`}>
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    marginLeft: 'none',
                    marginRight: 'none',
                }}
            >
                <Viewer fileUrl={fileContents}
                    plugins={[
                        defaultLayoutPluginInstance,
                    ]}
                    defaultScale={SpecialZoomLevel.PageFit}
                    renderPage={renderPage}
                />
            </div>
        </Worker>
    </div>
}

export default PDFViewer