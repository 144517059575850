class BaseError extends Error {}

export class InvalidURLError extends BaseError {}

export class ConnectionError extends BaseError {}

export class WeakPasswordError extends BaseError {}

export class InvalidPasswordError extends BaseError {}

export class PasswordRequiredError extends WeakPasswordError {}

export class PassphrasesDontMatch extends BaseError {}