import React from "react";

interface Props {
  filename: string,
  label: string,
  className: string,
  exportFile: () => Promise<any>,  // function to get the file content
  callback: () => Promise<void>  // callback for after the link was clicked
}

const Download = (props: Props) => {
  const downloadFile = (event: Event, text: any, fileName: string) => {
    const blob = new Blob([text], {
      type: "application/octet-stream"
    });

    const element = document.createElement("a");
    document.body.appendChild(element);
    element.setAttribute("href", window.URL.createObjectURL(blob));
    element.setAttribute("download", fileName);
    element.style.display = "";
    element.click();
    document.body.removeChild(element);
    event.stopPropagation();
  }

  const handleDownloadClick = (event: any) => {
    event.persist();
    event.preventDefault();

    const text = props.exportFile()

    if (text instanceof Promise) {
      text.then(result => downloadFile(event, result, props.filename));
    } else {
      downloadFile(event, text, props.filename);
    }
    props.callback()
  }

  return <button className={props.className} onClick={handleDownloadClick}>
      <span>
        {props.label}
      </span>
    </button>
}

export default Download;
