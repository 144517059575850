import React, { useState } from 'react';
import { EXPORT_STATUS } from '../Enums'
import { DownloadManager, getStorage, parseDownloadURL, getEnvType } from "../modules/DownloadManager";
import { Constants } from 'js-messenger'
import { Logger, LOG_LEVELS } from 'js-logger'

//Components
import { Files, LandingPage, SecureOptions } from './index'

//Css
import '../App.css';

//Assets
import Key from '../assets/Key.svg';
import Triangle from '../assets/Triangle.svg'
import { IDBStorage } from '../modules/IDBStorage';

export const useContainerHooks = () => {
    const [status, setStatus] = useState(EXPORT_STATUS.initial)
    const [downloadManager, setDownloadManager] = useState<any>(undefined)
    const [error, setError] = useState<Error | undefined>(undefined)
    const [fileInfo, setFileInfo] = useState<FileInfo[]>([])
    const [exid, setExid] = useState<Exid>('')
    const [forcePassword, setForcePassword] = useState<boolean>(false)
    const [firstTimeForRepeatContact, setFirstTimeForRepeatContact] = useState<boolean>(true)
    const handleAcceptExport = () => {
        setStatus(EXPORT_STATUS.passphrasePrompt)
        const { contactId, mpkId } = parseDownloadURL()
        setForcePassword(!!contactId)
        setFirstTimeForRepeatContact(!mpkId)
    }

    const handleReceivingFiles = () => {
        if (fileInfo.length > 0) {
            setStatus(EXPORT_STATUS.receivingFiles)
        }
    }

    const fileInfoCallback = async (fileInfo: FileInfo[]) => {
        console.log("got some files")
        setFileInfo(fileInfo)
    }

    const generateKeys = async (passphrase?: string) => {
        try {
            let dm = await DownloadManager.new(passphrase, fileInfoCallback)
            setDownloadManager(dm)
            return true
        } catch (e) {
            console.error("error", e)
            setError(e)
            setStatus(EXPORT_STATUS.error)
            return false
        }
    }

    const handleUpdatedDM = () => {
        setExid(downloadManager?.exidB64)
    }

    const handleOptionSelection = async (passphrase?: string) => {
        setStatus(EXPORT_STATUS.keyDerivationInProgress)
        if (!(await generateKeys(passphrase)))
            return
        if (status !== EXPORT_STATUS.receivingFiles) {
            setStatus(EXPORT_STATUS.waitingOnRelease)
        }
    }

    const getBrowserCompatibility = async (): Promise<{ isCompatible: boolean, message: string }> => {
        const compatibility = {
            isCompatible: true,
            message: ''
        }

        if (!window.indexedDB || !window.crypto?.subtle || !window.crypto?.getRandomValues) {
            compatibility.isCompatible = false
            compatibility.message = 'Your browser mode, settings, or version is incompatible with Secure File Transfer. Please update or change browsers to continue.'
        } else {
            // Needed to detect Firefox Private Browsing. window.indexedDB exists but throws errors.
            try {
                const name = 'COMPATIBILITY'
                await IDBStorage.new(name, 'generic', ['generic'], 1)
                indexedDB.deleteDatabase(name)
            } catch (error) {
                compatibility.isCompatible = false
                compatibility.message = 'Your browser mode, settings, or version is incompatible with Secure File Transfer. If you are in Private Mode try re-opening this link in a non-private window.'
            }
        }

        return compatibility
    }

    const init = () => {
        // Set the appropriate log level
        const env = getEnvType()
        if (env === Constants.ENV_TYPES.Development) {
            Logger.updateLogLevel(LOG_LEVELS.TRACE)
        } else {
            /* istanbul ignore next */
            Logger.updateLogLevel(LOG_LEVELS.OFF)
        }

        getBrowserCompatibility().then((compatibility: { isCompatible: boolean, message: string }) => {
            const { isCompatible, message } = compatibility
            if (isCompatible === false) {
                setError(new Error(message))
                setStatus(EXPORT_STATUS.error)
                return
            }
            checkIfReconnect()
        });
    }

    const checkIfReconnect = async () => {
        try {
            const { exid } = parseDownloadURL()
            const storage = await getStorage(exid)
            const reconnect = await storage.exidMetadata.has(exid)
            if (reconnect) {
                let dm = await DownloadManager.new(undefined, fileInfoCallback)
                setDownloadManager(dm)

                const allFileInfo = await storage.fileInfo.getAll()
                if (allFileInfo.length > 0) {
                    const updatedFileInfo = allFileInfo.map((item: { key: string, value: FileInfo }) => item.value)
                    setFileInfo(updatedFileInfo)
                } else {
                    if (status !== EXPORT_STATUS.receivingFiles) {
                        setStatus(EXPORT_STATUS.waitingOnRelease)
                    }
                }
            }
        } catch (e) {
            console.error("error", e)
            setError(e)
            setStatus(EXPORT_STATUS.error)
        }
    }

    return {
        functions: {
            checkIfReconnect,
            getBrowserCompatibility,
            fileInfoCallback,
            handleAcceptExport,
            handleOptionSelection,
            handleReceivingFiles,
            handleUpdatedDM,
            init,
            setStatus
        },
        state: {
            downloadManager,
            exid,
            fileInfo,
            status,
            forcePassword,
            firstTimeForRepeatContact,
            error
        }
    }
}

const Container = () => {
    const hooks = useContainerHooks();
    const { functions, state } = hooks;
    const { downloadManager, exid, fileInfo, status, error, forcePassword, firstTimeForRepeatContact } = state;
    const {
        handleAcceptExport,
        handleOptionSelection,
        handleReceivingFiles,
        handleUpdatedDM,
        init,
        setStatus
    } = functions;

    // This rule doesn't like that the functions called within the useEffect aren't in the dependency []. 
    // They are passed into the component and they could change.  These don't.  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(init, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(handleReceivingFiles, [fileInfo])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(handleUpdatedDM, [downloadManager])

    const waitingForRelease = () => {
        return <div className="standard-page-layout">
            <div className="help-text">The party sharing files with you may verify your identity by asking you to confirm the words below.</div>
            <h1>
                Recipient ID: <span className="profile-words">{downloadManager.words}</span>
            </h1>
            <object className="triangle-animation" type="image/svg+xml" data={Triangle}>svg-animation</object>
            <h2 className="waiting-text">Waiting for files to be released...</h2>
        </div>
    }

    const keyDerivation = () => {
        return <div>
            <object className="key-animation" type="image/svg+xml" data={Key}>svg-animation</object>
            <h2 className="waiting-text">Key generation in progress...</h2>
        </div>
    }

    // TODO: We need a way to easily test this
    const display = () => {
        switch (status) {
            case EXPORT_STATUS.initial:
                return <LandingPage handleAcceptExport={handleAcceptExport} />
            /* istanbul ignore next */
            case EXPORT_STATUS.passphrasePrompt:
                return <SecureOptions onOptionSelected={handleOptionSelection} forcePassword={forcePassword} firstTimeForRepeatContact={firstTimeForRepeatContact} />
            case EXPORT_STATUS.keyDerivationInProgress:
                return keyDerivation()
            case EXPORT_STATUS.waitingOnRelease:
                return waitingForRelease()
            case EXPORT_STATUS.error:
                return <div className="container-error"><span className="error-page-text">Error: {error ? error.message : "Unknown Error"}</span>
                    <button className="standard-button" onClick={() => setStatus(EXPORT_STATUS.initial)}>
                        <span>
                            Reset
                        </span>
                    </button>
                </div>
            /* istanbul ignore next */
            case EXPORT_STATUS.receivingFiles:
                return <Files fileInfo={fileInfo}
                    exid={exid}
                    onFilesDownloaded={async (oids: Oid[]) => { await downloadManager.sendExportDownloadComplete(oids) }} />
            default:
                return null;
        }
    }

    return (
        <div className="container">
            <React.Fragment>
                {display()}
            </React.Fragment>
        </div>
    );
}

export default Container;
