import React, { useState } from 'react';
import Download from './Download'
import { IDBStorage } from '../modules/IDBStorage'
import { LoaderIcon } from './LoaderIcon'
import { EXID_STORE_NAMES, DB_VERSION } from '../Constants'
import PDFViewer from './PDFViewer'

interface Props {
    exid: Exid,
    fileInfo: FileInfo[],
    onFilesDownloaded: (oids: Oid[]) => Promise<void>
}

const Files = (props: Props) => {
    const [storage, setStorage] = useState<Storage | undefined>(undefined)
    const [showPDF, setShowPDF] = useState<boolean>(false)
    const [pdf, setPDF] = useState<PDF | undefined>(undefined)
    const { exid, fileInfo, onFilesDownloaded } = props

    interface Storage {
        fileInfo: IDBStorage,
        decryptedFiles: IDBStorage
    }

    React.useEffect(() => {
        const openConn = async () => {
            let fInfo = await IDBStorage.new(exid, EXID_STORE_NAMES.FILE_INFO, Object.values(EXID_STORE_NAMES), DB_VERSION)
            let decFiles = await IDBStorage.new(exid, EXID_STORE_NAMES.DECRYPTED_FILES, Object.values(EXID_STORE_NAMES), DB_VERSION)
            setStorage({
                fileInfo: fInfo,
                decryptedFiles: decFiles
            })
        }
        openConn()
    }, [exid])

    const decryptedCount = fileInfo && fileInfo.filter(i => i.decrypted === true).length

    const getFileContent = async (oid: Oid) => {
        // TODO: handle failure to connect to DB gracefully.
        const content = await storage?.decryptedFiles?.get(oid)
        return content
    }

    const viewPDF = async (file: FileInfo) => {
        const fileContents = await getFileContent(file.objectID)
        const currentPDF: PDF = {
            watermarkText: file.watermarkText ?? '',
            watermarkOpacity: file.watermarkOpacity / 100 ?? 0,
            watermarkPosition: file.watermarkPosition ?? 'center',
            fileContents: fileContents
        }
        setPDF(currentPDF)
        setShowPDF(true)
    }

    const getFileButton = (file: FileInfo) => {
        if (file?.viewOnly && file.fileName?.slice(file.fileName.length - 4).toLocaleLowerCase() === '.pdf') {
            return <button id="view-button" className="file-button" onClick={() => viewPDF(file)}>
                <span>
                    View
                </span>
            </button>
        } else {
            return <Download
                label='Download'
                className="file-button"
                filename={file.fileName}
                exportFile={() => getFileContent(file.objectID)}
                callback={async () => { await onFilesDownloaded([file.objectID]) }}
            />
        }
    }

    const getFileDisplay = (files: FileInfo[]) => {
        const display: JSX.Element[] = []
        files.forEach((file) => {
            display.push(
                <div className="file-row" key={file.objectID}>
                    <span className="file-name">{file.fileName}</span>
                    {file.decrypted ? getFileButton(file) : <span className="loader-icon-container">
                        <LoaderIcon
                            height={40}
                            width={40}
                            color="#4368B1"
                            label="audio-loading"
                            radius={4} />
                    </span>
                    }
                </div>
            )
        })
        return display
    }

    if (showPDF && pdf) {
        return <PDFViewer togglePDF={setShowPDF} pdf={pdf} />
    } else {
        let displayString;
        if (!decryptedCount || !fileInfo.length) {
            displayString = 'Gathering file information...'
        } else {
            const fileCount = `${decryptedCount} / ${fileInfo.length} - `
            const readyStatus = decryptedCount === 1 ? 'File Ready' : 'Files Ready'
            
            displayString = fileCount + readyStatus
        }

        return <div className="standard-page-layout">
            <h3 className="file-container-header">{displayString}</h3>
            <div className="file-container">
                {getFileDisplay(fileInfo)}
            </div>
        </div>
    }
}

export default Files
