export const EXID_STORE_NAMES = {
    DECRYPTED_FILES: 'DECRYPTED_FILES',
    ENCRYPTED_CHUNKS: 'ENCRYPTED_CHUNKS',
    FILE_INFO: 'FILE_INFO',
    EXID_METADATA: 'EXID_METADATA'
}

export const EXID_METADATA_DB_NAME = "atakamaSecureExports"

export const META_STORE_NAMES = {
    EXID_META: 'EXID_META',
}

export const DB_VERSION = 1

export const EXPIRY_WINDOW_DAYS =  10  // ten days
